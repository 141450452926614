import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import ConfirmLoan from "../views/ConfirmLoan.vue";
import PaySuccess from "../views/PaySuccess.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/confirmLoan",
    name: "ConfirmLoan",
    component: ConfirmLoan,
  },
  {
    path: "/paySuccess",
    name: "PaySuccess",
    component: PaySuccess,
  },
];

const router = new VueRouter({
  routes,
  mode: "hash",
});

export default router;
