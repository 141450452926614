<template>
  <div class="confirm-loan">
    <div class="content">
      <div class="handle-card">
        <div class="handle-card-title">处理中</div>
        <div class="handle-card-info">预计2小时内放款成功，请注意查收！！</div>
        <div class="handle-card-img">
          <img class="" src="../assets/progress.png" alt="" />
        </div>
        <div class="handle-card-text">
          <div>确认借款</div>
          <div>放款中</div>
          <div>放款到账</div>
        </div>
      </div>
      <div class="info-card">
        <div class="info-card-item">
          <div>收款金额</div>
          <div>{{ amount }}</div>
        </div>
        <div class="solid"></div>
        <div class="info-card-item">
          <div>贷款期限</div>
          <div>{{ period }}</div>
        </div>
      </div>
      <div class="contact">
        <div class="tel">
          客服及投诉电话：<span>{{ servicePhone }}</span>
        </div>
        <div class="email">
          客服及投诉邮箱：<span>{{ serviceEmail }}</span>
        </div>
      </div>
      <div class="back-btn" @click="back">返回雪融花APP</div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "ConfirmLoan",
  data() {
    return {
      servicePhone: "4001601666",
      serviceEmail: "tousu@vcredi",
      amount: 0,
      period: "",
      orderId: "",
      // devurl: "http://121.199.11.4:18085/",
      devurl: " https://api.snowstormplus.com/",
    };
  },
  mounted() {
    this.orderId = this.$route.query.orderId;
    axios
      .get(this.devurl + "api/cash/cashOrderDetail", {
        params: {
          orderId: this.orderId,
        },
      })
      .then((response) => {
        this.servicePhone = response.data.data.servicePhone;
        this.serviceEmail = response.data.data.serviceEmail;
        this.amount = response.data.data.amount;
        this.period = response.data.data.period;
      })
      .catch((error) => {
        console.error(error);
      });
  },
  methods: {
    back() {
      flutterMethod.postMessage("back");
    },
  },
};
</script>
<style lang="less" scoped>
.confirm-loan {
  height: calc(100vh - 40px);
  .content {
    height: 100%;
    background-color: #f6f6f6;
    padding: 20px 30px;
    .handle-card {
      padding: 44px 32px;
      background-color: #fff;
      border-radius: 16px;
      .handle-card-title {
        font-weight: 600;
        font-size: 32px;
        color: #000000;
        line-height: 44px;
        text-align: left;
        font-style: normal;
      }
      .handle-card-info {
        margin-top: 4px;
        font-size: 26px;
        color: #969595;
        line-height: 36px;
        text-align: left;
        font-style: normal;
      }
      .handle-card-img {
        display: flex;
        justify-content: center;
        margin-top: 44px;
        img {
          width: 504px;
          height: 28px;
        }
      }
      .handle-card-text {
        color: #000;
        margin-top: 20px;
        display: flex;
        justify-content: space-between;
        font-size: 24px;
        padding: 0px 10px;
      }
    }
    .info-card {
      background-color: #fff;
      border-radius: 20px;
      margin-top: 16px;
      .info-card-item {
        font-weight: 600;
        font-size: 26px;
        color: #000000;
        display: flex;
        justify-content: space-between;
        padding: 32px;
      }
    }
    .contact {
      text-align: center;
      color: #7e8394;
      font-size: 22px;
      margin-top: 58px;
      .tel {
        span {
          color: #fa6400;
        }
      }
      .email {
        margin-top: 2px;
        span {
          color: #000;
        }
      }
    }
    .back-btn {
      width: 680px;
      height: 100px;
      margin-top: 44px;
      background: linear-gradient(90deg, #0c9ff4 0%, #0566e5 100%);
      border-radius: 52px;
      font-weight: 600;
      font-size: 34px;
      color: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .solid {
      width: 100%;
      border: 1px solid #f6f6f6;
    }
  }
}
</style>
