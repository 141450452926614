<template>
  <div class="pay-success">
    <div class="placeholder-img">
      <img class="" src="../assets/placeholder-img.png" alt="" />
    </div>
    <div class="tip">还款成功</div>
    <div class="bottom">
      <div class="back-btn" @click="back">完成</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PaySuccess",
  data() {
    return {};
  },
  methods: {
    back() {
      flutterMethod.postMessage("back");
    },
  },
};
</script>
<style lang="less" scoped>
.pay-success {
  .placeholder-img {
    display: flex;
    justify-content: center;
    margin-top: 258px;
    img {
      width: 173px;
      height: 152px;
    }
  }
  .tip {
    color: #010b3c;
    font-size: 34px;
    text-align: center;
    margin-top: 64px;
  }
  .bottom {
    display: flex;
    justify-content: center;
    .back-btn {
      width: 272px;
      height: 72px;
      color: #fff;
      font-size: 34px;
      background: #d50000;
      border-radius: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 102px;
    }
  }
}
</style>
